import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumPlayType } from "../../../auto_generated/enums";
import { EnumPlayStatus } from "../../../auto_generated/enums";
import { PlayStreamManager } from "../../../components/play/reports/play/play_stream_manager";
import { ComputedRole } from "../../base/vue_record";
import { PlayStatusGroupId } from "../../../helpers/play/play_status";
import { PlayTypeGroupId } from "../../../helpers/play/play_type";
import { TabTargetOpts } from "../../../components/testa/editor/tab";
import { PlayClient } from "../../clients/play/play_client";
import { play_action_title } from "../../../helpers/play/play_action";
import { play_action_color } from "../../../helpers/play/play_action";
import { play_action_icon } from "../../../helpers/play/play_action";
import { run_play_action } from "../../../helpers/play/play_action";
import { Image } from "../../models/image"
import SidebarTitle from "../../../components/play/reports/SidebarTitle.vue";
import { generate_resolved_promise } from "../../../helpers/generate/generate_resolved_promise";
import PlayStatusIcon from "../../../components/play/other/PlayStatusIcon.vue";
import { Editor } from "../../../components/testa/editor/editor";
import { PlayTab } from "../../../components/testa/editor/tabs/play_tab";
import _ from "lodash";
import { what_is_it } from "../../../helpers/generic/what_is_it";
import { multi_resource_remove } from "../../../helpers/client/core/multi_resource_remove";
import { on_dom_content_loaded } from "../../../helpers/events/dom_content_loaded";
import { watch } from "vue";
import { escape_regex } from "../../../helpers/generic/escape_regex";
import { PlayStatus } from "../../../helpers/play/play_status";
import { PlayType } from "../../../helpers/play/play_type";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";
import { PlaySettingProps, WorkerPops } from "./play_setting";
import { PlayScenarioProps } from "./play_scenario";
import { ScenarioProps } from "../scenario";
import { unmount_all_modals } from "../../../helpers/vue/unmount_all_modals";
import { resolve_tab_target } from "../../../components/testa/editor/tab";
import { create_vue_app } from "../../../helpers/vue/create_vue_app";
import { VueRecordScope } from "../../base/vue_record_scope";
import PlayGroupModal from "../../../components/play/queue/PlayGroupModal.vue";
import PlayScenarioModal from "../../../components/play/queue/PlayScenarioModal.vue";
import { Scenario } from "../scenario";
import { ScenarioFolder } from "../scenario_folder";
import { Group } from "../group";
import { PlayScope } from "../../scopes/play/play_scope";
import { computed } from "../../../helpers/vue/computed";
import { PlayScenario } from "./play_scenario";
import { PlayGroup } from "./play_group";
import { PlayLog } from "./play_log";
import { PlaySandbox } from "./play_sandbox";
import { PlaySetting } from "./play_setting";
import { PlaySnippet } from "./play_snippet";
import { PlayWorkerGroup } from "./play_worker_group";
import { PlayScenariosPlayWorkerGroups } from "./bridges/play_scenarios_play_worker_groups";
import { PlayGroupsPlayScenarios } from "./bridges/play_groups_play_scenarios";
import { DebugFrame } from "./play_snippet";
import { Data } from "faye";
import { ScenarioSetting } from "../scenario_setting";
import { UiSync } from "../../../helpers/ui_sync/ui_sync";
import UiOpenData = UiSync.UiOpenData;
import GetData = UiSync.GetData;
import { on_user_load } from "../../../helpers/events/on_user_load";
import { on_project_version_load } from "../../../helpers/events/on_project_version_load";
import { Schedule } from "../schedule";

// <editor-fold desc="TYPES">
export interface PlayProps extends Props {
    id: number
    project_version_id: number
    user_id: number
    schedule_id: number
    replay_from_play_id: number
    delayed_job_id: number
    play_setting_id: number
    scenario_setting_id: number
    name: string
    type: EnumPlayType
    duration: number
    status: EnumPlayStatus
    current_line_number: number
    total_lines_number: number
    master_comms_ready: boolean
    public_key: string
    created_at: Date
    updated_at: Date
    job_started_at: Date
    target_scenario_setting_id: number
    target_scenario_savepoint_id: number
    target_scenario_folder_savepoint_id: number
    target_group_savepoint_id: number
    report_created: boolean
    terminate_received: boolean
    master_worker_log_path: string
    terminated_by_user_id: number

    // from methods
    public_report_url: string
    "running?": boolean
    "finished?": boolean
    progress: number
    success_rate: number
}

export type QuerifiedPlayProps = QuerifyProps<PlayProps>
export type PlayCreateProps = Omit<PlayProps, 'id'>
export type PlayUpdateProps = Partial<PlayProps>

export interface PlayState extends State {
    play_stream_manager: PlayStreamManager
}

export interface PlayComputed extends ComputedRole {
    user_assigned_to_project: boolean
}

export interface PlayStaticState extends StaticState {
    load_promises: Record<number | string, Promise<Play>>
    filter: {
        user_ids: number[],
        play_status_group_ids: PlayStatusGroupId[],
        play_types_group_ids: PlayTypeGroupId[],
        name: string,
        from: Date,
        to: Date,
        schedule_id: number
        group_id: number
        scenario_folder_id: number
        scenario_id: number
        project_ids: number[],
        project_version_ids: number[],
    },
    computed_filter: {
        play_statuses: EnumPlayStatus[],
        play_types: EnumPlayType[],
        to_until_end_of_day: Date
    }
    filter_shown: boolean
    filter_label: string
    filter_play_scope: PlayScope,
    filter_result_state: {
        loading: boolean,
        all_loaded: boolean
        last_created_at: Date,
        last_response_failed: boolean
    }
}

export interface PlayOpenOpts extends TabTargetOpts {
    allow_duplicate?: boolean
    close_all_modals?: boolean
}


export interface PlayModalWorkerProps extends WorkerPops {
    vue_key: string
}

export interface PlayModalPlaySettingProps extends PlaySettingProps {
    workers: PlayModalWorkerProps[]
}

export type PlayModalResponse = {
    scenarios: ScenarioProps[],
    replay_from_main_play_scenarios: PlayScenarioProps[],
    id: number,
    name: string,
    play_setting: PlayModalPlaySettingProps,
    play_type: EnumPlayType
    replay_play_id?: number
    scenario_count: number
    api_id: number
}

type ActiveDebuggerInfo = { play_scenario_id: number, last_activation: Date, debug_frame_id: number }
// </editor-fold>

const play_filters_key = `${globalThis.current_project_version_props?.id}_${web.type}_play_filters`
const console = new Consoler("warn")

export class Play extends VueRecord {
    ['constructor']: typeof Play

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlayClient
    static ScopeClass = PlayScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlayStaticState = reactive<PlayStaticState>({
        load_promises: {} as Record<number | string, Promise<Play>>,
        filter: {
            user_ids: [],
            name: "",
            play_types_group_ids: [],
            play_status_group_ids: [],
            from: null,
            to: null,
            schedule_id: null,
            group_id: null,
            scenario_folder_id: null,
            scenario_id: null,
            project_ids: [],
            project_version_ids: [],
        },
        computed_filter: {
            play_statuses: [],
            play_types: [],
            to_until_end_of_day: null,
        },
        filter_shown: false,
        filter_label: null,
        filter_play_scope: null,
        filter_result_state: {
            all_loaded: false,
            loading: false,
            last_created_at: null,
            last_response_failed: false
        }
    });

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "project_version_id", "user_id", "type", "status"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof Play> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof Play>> = {}

    static field_validators: ModelValidatorOpts<PlayProps> = {}

    static resource_name = Enum.Resource.Label.PLAY
    static resource_id = Enum.Resource.Id.PLAY
    static icon_class = "fa-solid fa-clock-rotate-left"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlayClient
    declare props: PlayProps;
    declare state: PlayState;
    declare computed: PlayComputed;

    // </editor-fold>

    after_create() {
        super.after_create();

        this.init_computed_role(() => this.project_version?.props?.project_id)
        this.state.play_stream_manager = PlayStreamManager.new(this)
        this.computed.user_assigned_to_project = computed(() => {
            if (current.user == null) return false
            if (current.user?.is_superadmin()) return true
            const project_id = this.project_version.props.project_id
            return current.user.user_projects.where({ project_id }).count > 0
        })
    }

    after_update(new_props: PlayProps, old_props: PlayProps, changes: (keyof PlayProps)[]) {
        super.after_update(new_props, old_props, changes);
        if (this.props.user_id == current.user?.key() &&
            changes.includes("status") &&
            this.props.schedule_id != null &&
            (current.project_version?.key() == this.props.project_version_id || web.is_reports)
        ) {
            const before_statuses: string[] = [Enum.Play.Status.PENDING, Enum.Play.Status.SCHEDULED]
            const after_statuses: string[] = [Enum.Play.Status.BOOTING, Enum.Play.Status.NOT_STARTED, Enum.Play.Status.IN_PROGRESS]
            if (before_statuses.includes(old_props.status) &&
                after_statuses.includes(new_props.status)) {
                if (this.get_tabs().length == 0) this.open_in_reports()
            }
        }
    }

    before_unload() {
        super.before_unload();
        if (this.state.play_stream_manager != null) {
            this.state.play_stream_manager.stop_watchers()
        }
    }

    // <editor-fold desc="ACTIONS">
    static delete(project_version_id: number, play_ids: number | number[]) {
        let ids: number[];
        if (what_is_it(play_ids) == "Array") {
            ids = play_ids as number[]
        } else {
            ids = [play_ids as number]
        }
        return multi_resource_remove(project_version_id, Play.where({ id: ids }).to_scoped_map())
    }

    static clear_filters() {
        Play.state.filter.user_ids = []
        Play.state.filter.play_status_group_ids = []
        Play.state.filter.play_types_group_ids = []
        Play.state.filter.from = null
        Play.state.filter.to = null
        Play.state.filter.name = ""
        Play.state.filter.schedule_id = null
        Play.state.filter.scenario_folder_id = null
        Play.state.filter.group_id = null
        Play.state.filter.scenario_id = null
    }

    open_in_reports(opts: PlayOpenOpts = {}) {
        const web = ui_sync.web_for_reports()
        ui_sync.send_ui_open_task(web, {
            id: this.key(),
            resource_id: Play.resource_id,
            open_opts: opts,
        })
    }

    open(opts: PlayOpenOpts = {}) {
        if (opts.allow_duplicate == null) opts.allow_duplicate = false
        if (opts.close_all_modals) unmount_all_modals();
        let default_editor: Editor
        if (current.user?.props?.decouple_streams) {
            default_editor = Editor.get_footer()
        } else {
            default_editor = Editor.get_main()
        }
        const editor_or_tab_manager = resolve_tab_target(opts, default_editor)

        if (!opts.allow_duplicate) {
            const tabs = this.get_tabs()
            if (tabs.length > 0) {
                tabs.forEach(t => t.set_active(true))
                return generate_resolved_promise();
            }
        }

        const tab = editor_or_tab_manager.create_tab({
            id: `play_tab_${this.props.id}`,
            play_id: this.props.id,
            type: PlayTab.type
        }) as PlayTab

        return new Promise<void>((resolve, _reject) => {
            tab.on("editor_mounted", () => {
                resolve(null)
            })
        })
    }

    show_replay_modal() {
        if (this.props.type == Enum.Play.Type.SCENARIO) {
            create_vue_app(PlayScenarioModal, { replay_play: this })
        } else if ((this.props.type == Enum.Play.Type.SCENARIO_FOLDER || this.props.type == Enum.Play.Type.GROUP)) {
            create_vue_app(PlayGroupModal, { replay_play: this })
        }
    }

    static show_play_modal(records: VueRecord | VueRecord[] | VueRecordScope) {
        let rs: VueRecord[];
        if (records instanceof VueRecordScope) {
            rs = records.toArray()
        } else if (records instanceof Array) {
            rs = records
        } else {
            rs = [records]
        }
        const scenarios = rs.filter(r => r instanceof Scenario) as Scenario[]
        const scenario_folders = rs.filter(r => r instanceof ScenarioFolder) as ScenarioFolder[]
        const groups = rs.filter(r => r instanceof Group) as Group[]
        if (scenarios.length == 1 && scenario_folders.length == 0) {
            create_vue_app(PlayScenarioModal, { scenario_id: scenarios[0].key() })
        } else if (scenarios.length == 0 && scenario_folders.length == 1) {
            create_vue_app(PlayGroupModal, { scenario_folder_id: scenario_folders[0].key() })
        } else if (scenarios.length > 0 || scenario_folders.length > 0) {
            create_vue_app(PlayGroupModal, {
                scenario_folder_ids: scenario_folders.map(sf => sf.key()),
                scenario_ids: scenarios.map(s => s.key())
            })
        } else if (groups.length > 0) {
            create_vue_app(PlayGroupModal, { group_id: groups[0].key() })
        } else {
            toastr.error("Cannot determine what to play")
        }
    }

    // </editor-fold>

    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_plays_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    // <editor-fold desc="TREE">
    // <editor-fold desc="CONTEXT MENU">
    _tree_contextmenu() {
        return {
            build: (node: TestaTree.Node<PlayScope, PlayScope, Play>) => {
                const default_items = TestaTree.Tree.build_default_contextmenu(node);
                const items: ContextMenu.Items = {}

                if (this.is_finished() && this.props.report_created) {
                    items.replay = {
                        name: play_action_title('replay'),
                        color: play_action_color('replay'),
                        icon: play_action_icon('replay'),
                        callback: () => run_play_action(this, "replay")
                    }
                }

                if (!this.is_finished() && this.is_started()) {
                    items.stop = {
                        name: play_action_title('stop'),
                        color: play_action_color('stop'),
                        icon: play_action_icon('stop'),
                        callback: () => run_play_action(this, 'stop')
                    }
                }

                if (this.is_in_progress()) {
                    items.pause = {
                        name: play_action_title('pause'),
                        color: play_action_color('pause'),
                        icon: play_action_icon('pause'),
                        callback: () => run_play_action(this, 'pause')
                    }
                }

                if (this.is_in_debugging()) {
                    items.resume = {
                        name: play_action_title('resume'),
                        color: play_action_color('resume'),
                        icon: play_action_icon('resume'),
                        callback: () => run_play_action(this, 'resume')
                    }
                }

                items.copy_url = {
                    name: play_action_title('copy_url'),
                    color: play_action_color('copy_url'),
                    icon: play_action_icon('copy_url'),
                    callback: () => run_play_action(this, 'copy_url')
                }

                if (web.is_main && !ui_sync.is_reports_available()) {
                    items.reports_tab = {
                        name: play_action_title('reports_tab'),
                        color: play_action_color('reports_tab'),
                        icon: play_action_icon('reports_tab'),
                        callback: () => run_play_action(this, 'reports_tab')
                    }
                }

                if (this.project_version?.project_version_setting?.props?.xray_enabled) {
                    items.send_to_xray = {
                        name: "Send to Xray",
                        icon: "fas fa-link",
                        color: get_css_var("--button-white"),
                        callback: () => {
                            const play_ids = node.tree
                                                 .get_selected()
                                                 .map(n => n.record)
                                                 .compact()
                                                 .filter(r => r.constructor.resource_id == Enum.Resource.Id.PLAY)
                                                 .pluck("key")
                            Play.ClientClass
                                .send_to_xray(play_ids)
                                .then(() => toastr.info("Sending..."))
                                .catch(() => toastr.error('Failed to send to xray'))
                        },
                    }
                }

                items.show_target = {
                    name: play_action_title('show_target', this),
                    color: play_action_color('show_target', this),
                    icon: play_action_icon('show_target'),
                    callback: () => run_play_action(this, 'show_target')
                }

                return { ...items, ...default_items }
            }
        }
    }

    // </editor-fold>

    // <editor-fold desc="HOVER_ACTION">
    _hover_action_data(): TestaTree.HoverAction<PlayScope, PlayScope, Play> {
        return computed(() => {
            switch (this.props.status) {
                case Enum.Play.Status.IN_PROGRESS:
                    return {
                        icon: {
                            class: play_action_icon('pause'),
                            color: play_action_color('pause'),
                        },
                        title: play_action_title('pause'),
                        callback: () => this.client.pause()
                    } as TestaTree.HoverAction<PlayScope, PlayScope, Play>
                case Enum.Play.Status.DEBUGGING:
                    return {
                        icon: {
                            class: play_action_icon('resume'),
                            color: play_action_color('resume'),
                        },
                        title: play_action_title('resume'),
                        callback: () => this.client.resume()
                    } as TestaTree.HoverAction<PlayScope, PlayScope, Play>
                default:
                    if (this.is_finished()) {
                        return {
                            icon: {
                                class: play_action_icon('replay'),
                                color: play_action_color('replay'),
                            },
                            title: play_action_title('replay'),
                            callback: () => this.show_replay_modal()
                        } as TestaTree.HoverAction<PlayScope, PlayScope, Play>
                    }
                    return null
            }
        })
    }

    // </editor-fold>

    // <editor-fold desc="HOTKEYS">
    _testa_tree_hotkeys() {
        return computed(() => {
            const keys: Record<string, TestaTree.HotkeysCallback<PlayScope, PlayScope, VueRecord>> = {}
            return keys;
        })
    }

    // </editor-fold>

    testa_tree_node_data(): TestaTree.NodeInput<PlayScope, PlayScope, Play> {
        return {
            key: this.tree_key(),
            resource_id: Enum.Resource.Id.PLAY,
            record: this,
            title: computed(() => {
                return {
                    template: this.name(),
                    component: SidebarTitle,
                    component_props: { play: this }
                }
            }),
            duplicable: computed(() => false),
            deletable: (computed(() => !this.computed.role_is_viewer)),
            renaming: {
                renameable: computed(() => !this.computed.role_is_viewer),
                on_rename: (_node, new_name) => {
                    const old_name = this.props.name
                    this.props.name = new_name;
                    this.client
                        .update({ name: new_name })
                        .catch(() => this.props.name = old_name)
                }
            },
            file: {
                open_fn: () => {
                    this.open_in_reports()
                    return generate_resolved_promise()
                }
            },
            icon: computed(() => {
                return {
                    component: PlayStatusIcon,
                    component_props: {
                        play_status: this.props.status,
                        scale: 1.2
                    },
                }
            }),
            hover_action: this._hover_action_data(),
            hotkeys: this._testa_tree_hotkeys(),
            dnd: {
                is_draggable: false,
                is_drop_area: false,
            },
            contextmenu: this._tree_contextmenu(),
        }
    }

    // </editor-fold>

    // <editor-fold desc="HELPERS">
    is_pending() {
        return this.props.status == Enum.Play.Status.PENDING
    }

    is_scenario_type() {
        return this.props.type == Enum.Play.Type.SCENARIO
    }

    is_group_or_folder_type() {
        return this.props.type == Enum.Play.Type.GROUP || this.props.type == Enum.Play.Type.SCENARIO_FOLDER;
    }

    is_booting_status() {
        return this.props.status == Enum.Play.Status.BOOTING;
    }

    is_in_progress() {
        return this.props.status == Enum.Play.Status.IN_PROGRESS
    }

    is_in_debugging() {
        return this.props.status == Enum.Play.Status.DEBUGGING
    }

    is_finished() {
        return this.props["finished?"]
    }

    is_started() {
        return Enum.Play.Status.started_statuses.some(s => s == this.props.status)
    }

    is_running() {
        return this.props['running?']
    }

    static get_tabs() {
        return Editor.get_tabs().filter(t => t.type == PlayTab.type) as PlayTab[]
    }

    static unload_all_except_open() {
        const open_play_ids = Editor.get_tabs()
                                    .filter(tab => tab.state.record instanceof Play)
                                    .map(tab => tab.state.record as Play)
                                    .map(play => play.key())

        Play.get_scope()
            .not({ id: open_play_ids })
            .each(play => play.unload())
    }

    static on_filter_changed() {
        const play_filters = _.cloneDeep(Play.state.filter)
        delete play_filters.from
        delete play_filters.to
        current.storagers.project_version_web_type.set(play_filters_key, play_filters)
        Play.state.filter_result_state.last_created_at = null
        Play.state.filter_result_state.all_loaded = false
        Play.unload_all_except_open()
        Play.ClientClass.load_filtered()
    }

    is_group_type() {
        return Enum.Play.Type.group_types.some(t => t == this.props.type)
    }

    static contextmenu_item_play(callback: () => void) {
        return {
            name: "Play",
            icon: "fa fa-play",
            color: get_css_var("--button-green"),
            key: "f10",
            callback
        }
    }

    static get_active_play_scenario_debuggers() {
        return this.get_tabs()
                   .map(t => t.state.record.main_play_scenarios.toArray())
                   .flat()
                   .filter(play_scenario => play_scenario.is_debugging())
                   .filter(play_scenario => play_scenario.state.debugger_container?.checkVisibility())
                   .map(play_scenario => {
                       return {
                           play_scenario_id: play_scenario.key(),
                           last_activation: play_scenario.state.debugger_last_activation,
                           debug_frame_id: play_scenario.state.debugger_frame_id
                       } as ActiveDebuggerInfo
                   })
                   .sort_by(info => info.last_activation)
    }

    static get_active_play_scenario_debuggers_in_all_webs() {
        return new Promise<ActiveDebuggerInfo[]>((resolve, reject) => {
            ui_sync.send_get_active_play_scenario_debuggers<ActiveDebuggerInfo[]>().then(result => {
                resolve(result.flat().compact())
            }).catch(e => reject(e))
        })
    }

    static get_active_debugger() {
        return new Promise<ActiveDebuggerInfo>((resolve, reject) => {
            this.get_active_play_scenario_debuggers_in_all_webs().then(result => {
                resolve(result.sort_by(info => info.last_activation).last())
            }).catch(e => reject(e))
        })
    }

    static execute_code(code: string) {
        Play.get_active_debugger().then(active_debugger_information => {
            PlayScenario.ClientClass
                        .load(active_debugger_information.play_scenario_id)
                        .then(play_scenario => {
                            play_scenario.execute_command(code, active_debugger_information.debug_frame_id)
                        })
        })
    }

    // </editor-fold>

    // <editor-fold desc="INTERNAL">
    sync_relations() {
        console.log(`RUNNING SYNC RELATIONS for ${this.key()}`);
        PlayGroup.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_groups`)
        PlayLog.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_logs`)
        PlaySandbox.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_sandboxes`)
        PlayScenario.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_scenarios`)
        PlaySetting.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_settings`)
        PlaySnippet.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_snippets`)
        PlayWorkerGroup.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_worker_groups`)
        PlayScenariosPlayWorkerGroups.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_scenarios_play_worker_groups`)
        PlayGroupsPlayScenarios.sync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_groups_play_scenarios`)
        this.listen_for_commands()
    }

    unsync_relations() {
        PlayGroup.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_groups`)
        PlayLog.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_logs`)
        PlaySandbox.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_sandboxes`)
        PlayScenario.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_scenarios`)
        PlaySetting.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_settings`)
        PlaySnippet.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_snippets`)
        PlayWorkerGroup.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_worker_groups`)
        PlayScenariosPlayWorkerGroups.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_scenarios_play_worker_groups`)
        PlayGroupsPlayScenarios.unsync(`/sync/project_version/${this.props.project_version_id}/plays/${this.props.id}/play_groups_play_scenarios`)
        faye.unsubscribe(`/plays/${this.props.id}/commands`)
    }

    listen_for_commands() {
        const get_scenario_setting_phone_by_udid = (play_scenario: PlayScenario, target_udid: string) => {
            return play_scenario.main_play_scenario
                                .scenario_setting
                                ?.phones
                                ?.toArray()
                                ?.find(scenario_setting_phone => {
                                    const udid = scenario_setting_phone.phone_project
                                        .phone.props.udid
                                    return udid == target_udid
                                })
        }
        faye.subscribe(`/plays/${this.props.id}/commands`, (data: Data) => {
            console.log(data);
            const command = data.command;
            const play_scenario = PlayScenario.find(data.play_scenario_id);
            const play_snippet = PlaySnippet.find(data.play_snippet_id);
            switch (command) {
                case 'data_response': {
                    if (data.hasOwnProperty('command_history')) {
                        play_scenario.main_play_scenario.state.command_history = data.command_history;
                    }
                    if (data.hasOwnProperty("debug_frames")) {
                        if (play_snippet == null) break;

                        const debug_frames = play_snippet.state.debug_frames
                        while (debug_frames.length > 0) {
                            debug_frames.pop();
                        }
                        data.debug_frames.forEach((f: DebugFrame) => play_snippet.state.debug_frames.push(f));
                    }
                    if (data.hasOwnProperty("scenario_setting")) {
                        ScenarioSetting.new(data.scenario_setting)
                    }
                    break;
                }
                case "inspector_snapshot_loading": {
                    const scenario_setting_phone = get_scenario_setting_phone_by_udid(play_scenario, data.udid)
                    if (scenario_setting_phone?.state?.inspector != null) scenario_setting_phone.state.inspector.is_loading = true
                    break;
                }
                case "inspector_snapshot_loaded": {
                    const scenario_setting_phone = get_scenario_setting_phone_by_udid(play_scenario, data.udid)
                    if (scenario_setting_phone?.state?.inspector != null) scenario_setting_phone.state.inspector.is_loading = false
                    break;
                }
                case "inspector_status": {
                    const scenario_setting_phone = get_scenario_setting_phone_by_udid(play_scenario, data.udid)
                    if (scenario_setting_phone?.state?.inspector != null) scenario_setting_phone.state.inspector.is_loading = data.status == "loading"
                    break;
                }
                case Enum.Play.Action.REMOTE_SCREENSHOT: {
                    if (data.tab_id != TAB_ID) return;

                    const image_capture_url = data.screenshots[0]
                    Image.crop_external_image_in_main(image_capture_url, this.project_version.key())
                    break;
                }
            }
        })
    }

    // </editor-fold>
}

// <editor-fold desc="INIT">
Play.register_resource(Play)
PlayClient.ModelClass = Play
PlayScope.ModelClass = Play

if (web.is_reports) {
    // synced in project version hooks
} else if (web.is_main) {
    on_dom_content_loaded(() => {
        watch(
            () => current.project_version?.props?.id,
            (project_version_id) => {
                Play.unsync()
                if (project_version_id != null) Play.sync(`/sync/project_version/${project_version_id}/plays/*`)
                Play.state.filter_result_state.all_loaded = false
                Play.state.filter_result_state.last_created_at = null
                if (project_version_id != null) Play.on_filter_changed();
            },
            {
                flush: "sync",
                immediate: true
            }
        )
    })
} else if (web.is_single_report) {
    on_dom_content_loaded(function() {
        Play.sync(`/sync/project_version/${window.play_props.project_version_id}/plays/${window.play_props.id}`);
    });
}

if (web.is_main || web.is_reports) {
    on_dom_content_loaded(() => {
        Play.state.filter_label = computed(() => {
            let filter_count = 0;
            if (Play.state.filter.project_ids.length > 0) ++filter_count;
            if (Play.state.filter.project_version_ids.length > 0) ++filter_count;
            if (Play.state.filter.user_ids.length > 0) ++filter_count;
            if (Play.state.filter.play_status_group_ids.length > 0) ++filter_count;
            if (Play.state.filter.from != null || Play.state.filter.to != null) ++filter_count;
            if (Play.state.filter.play_types_group_ids.length > 0) ++filter_count;
            if (Play.state.filter.schedule_id != null) ++filter_count;
            if (Play.state.filter.group_id != null) ++filter_count;
            if (Play.state.filter.scenario_folder_id != null) ++filter_count;
            if (Play.state.filter.scenario_id != null) ++filter_count;
            const name_filter = Play.state.filter.name?.trim()
            if (name_filter != "" && name_filter != null) ++filter_count;

            if (filter_count == 0) return null
            return `${filter_count} filter${filter_count > 1 ? 's' : ''} applied`
        }) as any as string

        Play.state.filter_play_scope = computed(() => {
            let scope = Play.get_scope().where({ status: Enum.Play.Status.started_or_pending_statuses })
            if (web.is_main) scope = scope.where({ project_version_id: current.project_version?.key() })

            if (Play.state.filter.user_ids.length > 0) {
                scope = scope.where({ user_id: Play.state.filter.user_ids })
            }

            if (Play.state.filter.project_ids.length > 0) {
                scope = scope.where({ project_version: { project_id: Play.state.filter.project_ids } })
            }

            if (Play.state.filter.project_version_ids.length > 0) {
                scope = scope.where({ project_version_id: Play.state.filter.project_version_ids })
            }

            if (Play.state.computed_filter.play_types.length > 0) {
                scope = scope.where({ type: Play.state.computed_filter.play_types })
            }

            if (Play.state.computed_filter.play_statuses.length > 0) {
                scope = scope.where({ status: Play.state.computed_filter.play_statuses })
            }

            if (Play.state.filter.from != null) {
                scope = scope.greater_or_equal({ created_at: Play.state.filter.from })
            }

            if (Play.state.computed_filter.to_until_end_of_day != null) {
                scope = scope.lesser_or_equal({ created_at: Play.state.computed_filter.to_until_end_of_day })
            }

            if (Play.state.filter.schedule_id != null) {
                scope = scope.where({ schedule_id: Play.state.filter.schedule_id })
            }

            if (Play.state.filter.group_id != null) {
                scope = scope.where({ target_group_savepoint: { group_id: Play.state.filter.group_id } })
            }

            if (Play.state.filter.scenario_folder_id != null) {
                scope = scope.where({ target_scenario_folder_savepoint: { scenario_folder_id: Play.state.filter.scenario_folder_id } })
            }

            if (Play.state.filter.scenario_id != null) {
                scope = scope.where({ target_scenario_savepoint: { scenario_id: Play.state.filter.scenario_id } })
            }

            const name_filter = Play.state.filter.name?.trim()
            if (name_filter != "" && name_filter != null) {
                const words = name_filter.trim().split(" ")
                words.forEach(word => {
                    scope = scope.match({ name: new RegExp(escape_regex(word), "i") })
                })
            }

            return scope.order("job_started_at", "desc", "sensitive")
                        .order("created_at", "desc", "sensitive");
        }) as any as PlayScope

        Play.state.computed_filter.play_statuses = computed(() => {
            const statuses = Play.state.filter
                                 .play_status_group_ids
                                 ?.map(play_status_group_id => PlayStatus.get_statuses_for_filter_group(play_status_group_id))
                                 ?.flat()
                                 ?.filter(status => status != null)
            if (statuses == null) return []
            return statuses
        }) as any as EnumPlayStatus[]

        Play.state.computed_filter.play_types = computed(() => {
            const types = Play.state.filter
                              .play_types_group_ids
                              ?.map(play_type_group_id => PlayType.get_types_for_filter_group(play_type_group_id))
                              ?.flat()
                              ?.filter(type => type != null)
            if (types == null) return []
            return types
        }) as any as EnumPlayType[]


        Play.state.computed_filter.to_until_end_of_day = computed(() => {
            if (Play.state.filter.to == null) return null
            return new Date(Play.state.filter.to.getTime() + (1000 * 60 * 60 * 24) - 1)
        }) as any as Date
    })

// <editor-fold desc="LOAD SAVED STATE">
    try {
        const play_filters_shown_key = "play_filters_shown"
        const set_initial_filters = () => {
            if (current.user?.key() != null) Play.state.filter.user_ids = [current.user?.key()]
            const play_filters = current.storagers.project_version_web_type.get(play_filters_key, Play.state.filter)
            Object.assign(Play.state.filter, play_filters)

            Play.state.filter_shown = current.storagers.project_version_web_type.get(play_filters_shown_key, Play.state.filter_shown)
        }

        if (web.is_reports) {
            on_user_load(set_initial_filters)
        } else {
            on_project_version_load(() => on_user_load(set_initial_filters))
        }

        on_dom_content_loaded(() => {
            watch(() => Play.state.filter_shown,
                () => {
                    current.storagers.project_version_web_type.set(play_filters_shown_key, Play.state.filter_shown)
                })

            watch(() => Play.state.filter.project_ids,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })
            watch(() => Play.state.filter.project_version_ids,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })
            watch(() => Play.state.filter.from,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })
            watch(() => Play.state.filter.to,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })
            watch(() => Play.state.filter.user_ids,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })
            watch(() => Play.state.filter.play_types_group_ids,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })
            watch(() => Play.state.filter.play_status_group_ids,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })
            watch(() => Play.state.filter.name,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })
            watch(() => Play.state.filter.schedule_id,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    Play.on_filter_changed()
                })

            watch(() => Play.state.filter.group_id,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    if (Play.state.filter.group_id != null && Group.find(Play.state.filter.group_id) == null) Group.ClientClass.load(Play.state.filter.group_id)
                    Play.on_filter_changed()
                })

            watch(() => Play.state.filter.scenario_folder_id,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    if (Play.state.filter.scenario_folder_id != null && ScenarioFolder.find(Play.state.filter.scenario_folder_id) == null) ScenarioFolder.ClientClass.load(Play.state.filter.scenario_folder_id)
                    Play.on_filter_changed()
                })

            watch(() => Play.state.filter.scenario_id,
                (new_val, old_val) => {
                    if (_.isEqual(new_val, old_val)) return;
                    if (Play.state.filter.scenario_id != null && Scenario.find(Play.state.filter.scenario_id) == null) Scenario.ClientClass.load(Play.state.filter.scenario_id)
                    Play.on_filter_changed()
                })

            if (Play.state.filter.group_id != null && Group.find(Play.state.filter.group_id) == null) {
                Group.ClientClass.load(Play.state.filter.group_id)
            }
            if (Play.state.filter.scenario_folder_id != null && ScenarioFolder.find(Play.state.filter.scenario_folder_id) == null) {
                ScenarioFolder.ClientClass.load(Play.state.filter.scenario_folder_id)
            }
            if (Play.state.filter.scenario_id != null && Scenario.find(Play.state.filter.scenario_id) == null) {
                Scenario.ClientClass.load(Play.state.filter.scenario_id)
            }
            Play.on_filter_changed();
        })
    } catch (e) {
    }
// </editor-fold>
}

if (web.is_main || web.is_reports) {
    ui_sync.register_ui_task("open", Enum.Resource.Id.PLAY, (_sender: string, data: UiOpenData) => {
        Play.ClientClass.load(data.id).then(play => play.open(data.open_opts))
    })
    ui_sync.register_get_task("get_active_play_scenario_debuggers", (_sender: string, data: GetData) => {
        return Play.get_active_play_scenario_debuggers()
    })
}

declare global {
    interface Window {
        Play: typeof Play
    }
}
window.Play = Play
// </editor-fold>
