import { VueRecord } from "../../base/vue_record";
import { BelongsToAssociations } from "../../base/vue_record";
import { HasManyAssociations } from "../../base/vue_record";
import { HasOneAssociations } from "../../base/vue_record";
import { HasManyThroughAssociations } from "../../base/vue_record";
import { ModelValidatorOpts } from "../../../helpers/validator/validator";
import { get_css_var } from "../../../helpers/generic/get_css_var";
import { Computed } from "../../base/vue_record";
import { VueRecordStore } from "../../base/vue_record_store";
import { VueRecordIndex } from "../../base/vue_record_index";
import { reactive } from "../../../helpers/vue/reactive";
import { TestaTree } from "../../../components/testa/tree/tree";
import { Consoler } from "../../../helpers/api_wrappers/consoler";
import { QuerifyProps } from "../../base/vue_record_scope";
import { EnumBrowserValues } from "../../../auto_generated/enums";
import { EnumPlaySettingOnError } from "../../../auto_generated/enums";
import { EnumPlaySettingDelayPreposition } from "../../../auto_generated/enums";
import { EnumPlaySettingPlayInTimeUnits } from "../../../auto_generated/enums";
import { EnumPlaySettingRepeatEveryTimeUnits } from "../../../auto_generated/enums";
import { EnumPlaySettingSlackReportOn } from "../../../auto_generated/enums";
import { EnumPlaySettingSelectedNoReset } from "../../../auto_generated/enums";
import { PlaySettingClient } from "../../clients/play/play_setting_client";
import { PlaySettingScope } from "../../scopes/play/play_setting_scope";
import { Props } from "../../base/vue_record";
import { State } from "../../base/vue_record";
import { StaticState } from "../../base/vue_record";

// <editor-fold desc="TYPES">
export type ReplaceBrowser = {
    version: number | string
    is_mobile?: boolean
    width?: number
    height?: number
    type?: EnumBrowserValues
    emulation_profile_name?: string
}

export type BrowserMapping = {
    [key: string]: string | ReplaceBrowser
}

export interface PhoneMapping {
    any_android?: string | number
    any_iphone?: string | number
    [key: string]: string | number
}

export interface MobileAppMapping {
    [key: string]: {
        version: string | number
        package: string
    }
}

export interface WorkerPops {
    name: string
    load: number
    extra_variables: string
}

export interface ScenarioSettingMapping {
    browsers?: BrowserMapping
    mobile_browsers?: BrowserMapping
    phones?: PhoneMapping
    mobile_apps?: {
        android?: MobileAppMapping
        iphone?: MobileAppMapping
        simulator?: MobileAppMapping
    }
    proxies?: {
        [key: string]: number
    }
    locale?: {
        [key: string]: string
    }
}

export interface PlaySettingProps extends Props {
    id: number
    scenario_id: number
    scenario_folder_id: number
    group_id: number
    replay_failed: boolean
    name: string
    on_error: EnumPlaySettingOnError
    enable_video_recording: boolean
    perform_later: boolean
    delay_preposition: EnumPlaySettingDelayPreposition
    play_in_amount: number
    play_in_time_units: EnumPlaySettingPlayInTimeUnits
    perform_target_datetime: Date
    repeat: boolean
    repeat_every_amount: number
    repeat_every_time_units: EnumPlaySettingRepeatEveryTimeUnits
    slack_channel_id: string
    slack_message_ts: string
    slack_message_text: string
    slack_bot_token: string
    slack_send_report_on: EnumPlaySettingSlackReportOn
    slack_send_report_threshold: number
    selected_no_reset: EnumPlaySettingSelectedNoReset
    reuse_browsers: boolean
    target_success: number
    report_to_xray: boolean
    scenario_setting_mappings: ScenarioSettingMapping
    parallel_execution: boolean
    workers: WorkerPops[]
    extra_settings: string
    created_at: Date
    updated_at: Date
    replay_reload_source: boolean
    replay_success: boolean
    replay_warned: boolean
    replay_skipped: boolean
    replay_not_finished: boolean
    variable_set_id: number
    variable_set_savepoint_id: number
    default_variable_set_savepoint_id: number
    auto_replay_failed: boolean
}
export type QuerifiedPlaySettingProps = QuerifyProps<PlaySettingProps>
export type PlaySettingCreateProps = Omit<PlaySettingProps, 'id'>
export type PlaySettingUpdateProps = Partial<PlaySettingProps>

export interface PlaySettingState extends State {}
export interface PlaySettingComputed extends Computed {}
export interface PlaySettingStaticState extends StaticState {}

// </editor-fold>

const console = new Consoler("warn")
export class PlaySetting extends VueRecord {
    ['constructor']: typeof PlaySetting

    // <editor-fold desc="STATIC PROPERTIES">
    static relations_established = false
    static ClientClass = PlaySettingClient
    static ScopeClass = PlaySettingScope
    static readonly primary_key = "id"
    static sync_channels: string[] = []
    static state: PlaySettingStaticState = reactive<PlaySettingStaticState>({});

    static belongs_to_associations: BelongsToAssociations = []
    static has_many_associations: HasManyAssociations = []
    static has_one_associations: HasOneAssociations = []
    static has_many_through_associations: HasManyThroughAssociations = []
    static inverse_has_many_through: HasManyThroughAssociations = []
    static indexes = [
        VueRecordIndex.new(this),
        VueRecordIndex.new(this, "scenario_id"),
        VueRecordIndex.new(this, "scenario_folder_id"),
        VueRecordIndex.new(this, "group_id"),
    ]

    static indexed_columns: string[]
    static store: VueRecordStore<typeof PlaySetting> = VueRecordStore.new(this)
    static stages_store: Record<string, VueRecordStore<typeof PlaySetting>> = {}

    static field_validators: ModelValidatorOpts<PlaySettingProps> = {}

    static resource_name = Enum.Resource.Label.PLAY_SETTING
    static resource_id = Enum.Resource.Id.PLAY_SETTING
    static icon_class = "fa-solid fa-wrench"
    static color = () => get_css_var("--play-color")
    // </editor-fold>

    // <editor-fold desc="PROPERTIES">
    declare client: PlaySettingClient
    declare props: PlaySettingProps;
    declare state: PlaySettingState;
    declare computed: PlaySettingComputed;

    // </editor-fold>


    duplicate() {
        // do nothing here
    }

    show_in_sidebar(_tree: TestaTree.Tree = TestaTree.Tree.get_plays_tree()): Promise<void> {
        throw new Error("Method not implemented.");
    }

    testa_tree_node_data(): TestaTree.NodeInput<any, any, any> {
        throw new Error("Method not implemented.");
    }
}

// <editor-fold desc="INIT">
PlaySetting.register_resource(PlaySetting)
PlaySettingClient.ModelClass = PlaySetting
PlaySettingScope.ModelClass = PlaySetting


declare global {
    interface Window {
        PlaySetting: typeof PlaySetting
    }
}
window.PlaySetting = PlaySetting
// </editor-fold>

